@import "theme";

/* <==== SCREEN-SIZES ====> */
// based on Bootstrap grid
$xxs-width: 360px;
$xs-width: 576px;
$sm-width: 768px;
$md-width: 992px;
$lg-width: 1200px;
$xl-width: 1400px;
$xxl-width: 1680px;
/* <==== END SCREEN-SIZES ====> */

/* <==== MEDIA QUERIES ====> */
// desktop-first, using max-width
@mixin xxl {
  @media screen and (max-width: #{$xxl-width}) {
    @content;
  }
}

@mixin xl {
  @media screen and (max-width: #{$xl-width}) {
    @content;
  }
}

@mixin lg {
  @media screen and (max-width: #{$lg-width}) {
    @content;
  }
}

@mixin md {
  @media screen and (max-width: #{$md-width}) {
    @content;
  }
}

@mixin sm {
  @media screen and (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin xs {
  @media screen and (max-width: #{$xs-width}) {
    @content;
  }
}

@mixin xxs {
  @media screen and (max-width: #{$xxs-width}) {
    @content;
  }
}

/* <==== END MEDIA-QUERIES ====> */

/* <==== DISPLAY FLEX ====> */
@mixin flex($ff) {
  display: flex;
  @if $ff == lt {
    justify-content: flex-start;
    align-items: flex-start;
  } @else if $ff == lc {
    justify-content: flex-start;
    align-items: center;
  } @else if $ff == lb {
    justify-content: flex-start;
    align-items: flex-end;
  } @else if $ff == ct {
    justify-content: center;
    align-items: flex-start;
  } @else if $ff == cc {
    justify-content: center;
    align-items: center;
  } @else if $ff == cb {
    justify-content: center;
    align-items: flex-end;
  } @else if $ff == rt {
    justify-content: flex-end;
    align-items: flex-start;
  } @else if $ff == rc {
    justify-content: flex-end;
    align-items: center;
  } @else if $ff == rb {
    justify-content: flex-end;
    align-items: flex-end;
  } @else if $ff == st {
    justify-content: space-between;
    align-items: flex-start;
  } @else if $ff == sc {
    justify-content: space-between;
    align-items: center;
  } @else if $ff == sb {
    justify-content: space-between;
    align-items: flex-end;
  } @else if $ff == ls {
    justify-content: flex-start;
    align-items: stretch;
  } @else if $ff == cs {
    justify-content: center;
    align-items: stretch;
  } @else if $ff == rs {
    justify-content: flex-end;
    align-items: stretch;
  } @else if $ff == ss {
    justify-content: space-between;
    align-items: stretch;
  } @else if $ff == sn {
    justify-content: space-between;
    align-items: baseline;
  } @else {
  }
}

/* <==== END DISPLAY FLEX ====> */

/* <==== UI-STYLES ====> */
%bow-shadow--bottom {
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.16);
}

%background-image-center-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

// avoid using it
%background-backdrop-filer-default {
  backdrop-filter: blur(5px) contrast(0.8) brightness(50%);
}

@mixin block-fixed-ratio($w, $h, $absolute) {
  height: unset !important;

  &:after {
    content: '';
    display: block;
    padding-bottom: $h / $w * 100%;
    position: relative;
  }
  @if ($absolute == true) {
    > * {
      position: absolute;
    }
  }
}

/* <==== END UI-STYLES ====> */

@mixin text-max-lines($lines) {
  white-space: normal;

  display: -webkit-box;
  -webkit-box-orient: vertical;

  line-clamp: $lines;
  -webkit-line-clamp: $lines;

  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin text-various {
  &--color {
    &-W {
      color: $text-color-default;
    }
    &-G {
      color: $text-color-dark;
    }
    &-B {
      color: $text-color-black;
    }
  }
}

%scroll {
  // for Firefox
  scrollbar-width: thin;
  scrollbar-color:  $color-accent-hover rgba(0, 0, 0, .5);

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;

    @include sm {
      width: 3px;
      height: 3px;
    }
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, .2);
  }

  &::-webkit-scrollbar-thumb {
    background: $color-accent-hover;
  }
}

%generic-link {
  color: $color-accent-hover;
  //@include flex(lc);

  &::before {
    content: "\f35d";
    margin-right: 10px;
    @extend %font-awesome-icon;
  }
}

%full-height-header {
  min-height: $full-height;
}

%font-awesome-icon {
  font-size: 1rem;
  font-weight: 900;
  //font: var(--fa-font-solid);
  font-family: 'Font Awesome 5 Free';
}

%image-full-size {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
