// font-size
$text-size-extra: 32px;
$text-size-large: 16px;
$text-size-default: 14px;
$text-size-small: 12px;

// font-weight
$text-weight-normal: 400;
$text-weight-thin: 300;
$text-weight-bold: 600;

// font-colors
//$text-color-default: #383838;
//$text-color-light: #616161;

$text-color-default: #ebebeb;
$text-color-dark: #5a5a5a;
$text-color-black: #191919;

$color-accent: #9b0000;
$color-accent-hover: #d20000;
$color-accent-active: #ff0000;

$color-default: #3d3d3d;
$color-default-hover: #4b4b4b;
$color-default-active: #5f5f5f;

//$color-danger-dark: #9b0000;
//$color-danger: #d20000;
//$color-danger-light: #ff9f9f;

$color-success: #216710;

$background-color-back: #000;
$background-color-front: #191919;
$background-color-accent: #232323;

$border-color-default: #353635;
$border-color-lighter: #5a5a5a;
$border-default: 1px solid;
$border-thicker: 2px solid;

// element styles
$border-radius-default: 3.5px;

// colors
$color-black: #000;

$padding-tiny: .5rem;
$padding-small: 12px;
$padding-default: 16px;
$padding-large: 24px;
$padding-huge: 36px;

$content-max-width: 2560px;

$header-height: 64px;

$full-height: calc(100vh - #{$header-height});
$slide-max-width: 920px;

$white: #fff;

$font-main: 'Roboto Condensed', sans-serif;
$font-text: 'Merriweather', serif;
$font-buttons: 'Montserrat', sans-serif;

