@import "./../../styles/mixins";
@import "./../../styles/theme";

.fetcher-loader {
  position: absolute;
  top:0;
  left:0;
  z-index: 9;

  width: 100%;
  height: 100%;
  max-height: 100vh;

  background-color: rgba(0, 0, 0, .9);

  @include flex(cc);

  & > div {
    @include flex(cc);
  }
}