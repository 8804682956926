@import "./../../styles/theme";
@import "./../../styles/mixins";

//.modal-backdrop {
//  opacity: unset !important;
//  background-color: unset !important;
//  backdrop-filter: blur(10px) brightness(50%);
//}
//
///* slightly transparent fallback */
//.backdrop-blur {
//  background-color: rgba(255, 255, 255, .9);
//}

.modal-backdrop {
  //opacity: 0.8 !important;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .modal-backdrop {
    opacity: unset !important;
    background-color: unset !important;
    backdrop-filter: blur(10px) brightness(50%);
    //background-color: rgba(255, 255, 255, .5);
    //-webkit-backdrop-filter: blur(2em);
    //backdrop-filter: blur(2em);
  }
}

.order-modal {

  @include lg {
    overflow-y: hidden;

    touch-action: none;
    -ms-touch-action: none;
  }

  .modal {

    will-change: display;

    &-content {
      background-color: $background-color-front;
      border-radius: 0;
      padding: 1rem 2rem;

      @extend %scroll;

      @include xs {
        padding: 1rem .5rem;
        border:none;
        height: 100vh;
        overflow-y: auto;
      }

      overscroll-behavior: none;
    }

    &-header {
      border-bottom: 1px solid $color-default;
    }

    &-dialog {
      max-width: 555px;

      @include xs {
        max-width: unset;
        margin: 0;
        align-items: flex-start;
      }

      will-change: transform;
    }

    &-body {
      position: initial !important;

      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  &__header {
    padding: 0 0 2rem 0;
  }

  input {
    //background-color: $background-color-back;
  }

  //select {
  //
  //}

  &__form {

    textarea {
      resize: vertical;
      width: 100%;
      min-height: 90px;
      max-height: 600px;
    }

    .fetcher-loader {
      background-color: rgba(0, 0, 0, .6);
    }
  }

  button:not(.order-modal__close) {
    padding: 15px 25px 14px;
    border-radius: 2px;
    box-shadow: none;
    //min-width:120px;
    font-size: 15px;
    //font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .3px;
  }

  .input-checkbox {
    @include flex(lt);
    //max-height: 32px;
    label {
      margin-top: 2px;
      width: 18px;
      height: 18px;
      position: relative;
      display: block;
      margin-right: 3px;

      @include lg {
        margin-right: 7px;
      }
    }

    & > div {
      white-space: normal;
    }
  }

  &__success-message {

    &__icon {

      font-size: 36px;
      margin-bottom:10px;
    }

    a {
      color: $color-accent-active;

      &::before {
        content: "\f35d";
        margin-right: 5px;
        font-size: 14px;
        margin-left: 6px;
        @extend %font-awesome-icon;
      }

      &:hover {
        color: $color-accent-hover;
      }
    }
  }

  &__close {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 10px;
    right: 10px;

    font-size: 18px;
    color: $text-color-default;
    background-color: transparent;
    border:none;
    padding:0;
  }
}
