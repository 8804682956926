@import './../../styles/theme';
@import './../../styles/mixins';

$form-color: #171717;
$border-color: #313131;

.input {
  &-wrapper {

    .input-icon.fa-icon-wrapper {
      @include flex(cc);
    }

    @include flex(lc);
    position: relative;

    input.form-control, textarea.form-control {
      border: 1px solid $border-color;
      //border-left:none !important;
      border-radius: 0 2px 2px 0;
      color: $text-color-default;
      outline: none;
      background-color: $form-color;
      height: 46px;
      padding: 0.375rem 1rem;
      //margin: 0.5rem 0;
      box-shadow: none !important;

      &:focus {
        border: 1px solid $text-color-default;
      }
    }

    .input-icon {
      width: 46px;
      height: 46px !important;
      background-color: $form-color;
      border: 1px solid $border-color;
      border-right: none;
    }
  }

  &-text {
    background-color: $color-default;
  }

  &-text.disabled {
    opacity: .5;
    pointer-events: none !important;
    cursor: unset !important;
  }

  &-select {
    height: 46px; // dammit!!!
    //border: none !important;
    border: 1px solid $border-color;
    border-radius: 0 2px 2px 0;
    background-color: $form-color !important;
    color: #6c757d;
    outline: none !important;
    box-shadow: none !important;

    &:focus, &:active {
      color: $text-color-default;
      border: 1px solid $text-color-default;
    }
  }

  // error message
  &-error {
    position: absolute;
    bottom: 100%;
    transform: translateY(50%);
    //right: 1rem;
    right: 0;
    color: $color-accent-active;
    //font-size: 0.85rem;
    font-size: 11px;
    font-family: $font-buttons;
    text-transform: uppercase;
    //font-weight: 700;
    //font-style: italic;
    //max-width: 30%;
    line-height: 1rem;
    top: 100%;

    white-space: nowrap;
  }
  &-checkbox .input-error {
    //display: none !important;
    //top: calc(100% + 10px);
  }

  &-checkbox {
    label {
      //margin-left: 10px;
      width: 18px;
    }
  }

  &-button {
    width: 46px;
    height: 46px;

    position:absolute;
    top:0;
    right:0;
    cursor: pointer;
    z-index: 2;
  }

  &-checkbox.form-error {
    label:before {
      border-color: $color-accent-active !important;
    }

    .input-error {
      //display: none;
      //top:50%;
      left:0;
      top: calc(100% + 3px);
    }
  }
}