@import "./../../styles/theme";
@import "./../../styles/mixins";

.form {

  .loader {
    position: absolute !important;
  }

  &--submitting {
    user-select: none !important;
    //opacity: 0.5;
    pointer-events: none !important;
  }

  &__spinner {

    @include flex(cc);
    background-color: rgba($background-color-front, 0.7);
    z-index: 3;

    &-inner {

      @include flex(cc);

      span span {

        color: $color-accent !important;
        background-color: $color-accent !important;
      }
    }
  }
}